import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Select from 'react-select';
import GridSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Table } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import SearchChildrenForm from './modules/forms/searchChildrenForm.json';
import ChildrenGridForm from './modules/forms/childrenGridForm.json';
import EnumData from '../EnumData.json';
import Districts from '../Districts.json';
import Buttons from '../Buttons.json';
import Switch from '@material-ui/core/Switch';
import TableFooter from '@material-ui/core/TableFooter';
import MenuItem from '@material-ui/core/MenuItem';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListSubheader from '@material-ui/core/ListSubheader';
import MaterialSelect from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ViewChildData from './ViewChildData';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
var request = require("request-promise");

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 250
  }
}))
const useStylesnav = makeStyles(theme => ({
  select: {
    maxWidth: 250
  },
  level2: {
    paddingLeft: theme.spacing(3),
    maxHeight: 25
  },
  level3: {
    paddingLeft: theme.spacing(5),
    maxHeight: 25
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}))

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Поиск детей и формирование запросов на встречу
  const [userRole, setUserRole] = useState(null)
  const [Form, setForm] = useState(null)
  const [docList, setDocList] = useState(null)
  const [gridFormData, setGridFormData] = useState(null)
  const [gridForm] = useState(ChildrenGridForm)
  const [gridFormButtons, setGridFormButtons] = useState(null)
  const [enumData, setEnumData] = useState(null)
  const [fieldValue, setFieldValue] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])
  const [buttons, setButtons] = useState(null)
  const [page, setPage] = useState(1)
  const [pageAmount, setPageAmount] = useState(null)
  const [size, setSize] = useState(10)
  const [task, setTask] = useState(10)
  const [childId, setChildId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [countMeetUpRequests, setCountMeetUpRequests] = useState({})
  const [filtered, setFiltered] = useState(false)


  const classes = useStyles()
  const classesnav = useStylesnav()
  useEffect(() => {
    // console.log("ROLE", props.userRole)
    let uRole = props.userRole
    setUserRole(uRole)
    if (props.taskType === "getActiveList") {
      setUserId(props.userId)
      fetchChildren()
      setForm(SearchChildrenForm)
      setButtons(Buttons[uRole].SearchChildrenButtons)
      setGridFormButtons(Buttons[uRole].ChildrenGridFormButtons)
      setEnumData(EnumData)
      setTask(props.taskType)
    }
  }, [])
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalSeparator={","}
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }
  function getUserStatus() {
    props.getUserStatus()
  }
  // function updateSOP(){
  //   for(let i=0; i<EnumData.length; i++){
  //     for(let l=0; l<EnumData[i].data.length; l++){
  //       if(EnumData[i].data[l].Id === props.userOrgId){
  //         // console.log("DIST", EnumData[i].data[l].Id, props.userOrgId)
  //         let updatedSOP= []
  //         updatedSOP.push({name: "OrgId", value: EnumData[i].data[l].Id, label: EnumData[i].data[l].Text})
  //         setSelectedOptions(updatedSOP)
  //       }
  //     }
  //   }
  // }
  async function fetchChildren() {
    console.log("GETLIST", props.userId)
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetActiveList?userId=" + props.userId,
        "method": "GET"
      }
    )
      .then(function (response) {
        console.log("RESPONSE", JSON.parse(response))

        var parsedData = JSON.parse(response)
        setGridFormData(parsedData)
        if (filtered === true) {
          filterDocList(0, 10, parsedData)
        }
        else {
          setDocList([])
        }
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })
  }
  async function getCountMeetUpRequestByChildId(id) {
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetByChildId?childId=" + id,
        "method": "GET"
      }
    )
      .then(function (response) {
        // console.log("RESPONSE", JSON.parse(response))
        var parsedData = JSON.parse(response)
        setCountMeetUpRequests(parsedData)
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })
  }
  function parseDate(date) {
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var hour = newDate.getHours()
    var minutes = newDate.getMinutes()
    var convertedDate = dd + '-' + mm + '-' + yyyy + '  ' + hour + ':' + minutes
    return convertedDate
  }
  function parseDateComponent(date) {
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var convertedDate = yyyy + '-' + mm + '-' + dd
    return convertedDate
  }
  function filterDocList(fetchFrom, fetchTo, Data) {
    setFiltered(true)
    setDocList([])
    var newDocList = []
    for (let i = 0; i < Data.length; i++) {
      let matchOrgId = false
      let matchNationality = false
      let matcGender = false
      let matchAgeFrom = false
      let matchAgeTo = false

      if (fieldValue["OrgId"] !== "" && fieldValue["OrgId"] !== undefined && fieldValue["OrgId"] !== "none") {
        if (fieldValue["OrgId"] === Data[i].OrgId) {
          matchOrgId = true
        } else { matchOrgId = false }
      } else { matchOrgId = true }

      if (fieldValue["Nationality"] !== "" && fieldValue["Nationality"] !== undefined) {
        if (fieldValue["Nationality"] === Data[i].Nationality) {
          matchNationality = true
        } else { matchNationality = false }
      } else { matchNationality = true }

      if (fieldValue["Gender"] !== "" && fieldValue["Gender"] !== undefined) {
        if (fieldValue["Gender"] === Data[i].Gender) {
          matcGender = true
        } else { matcGender = false }
      } else { matcGender = true }

      if (fieldValue["AgeFrom"] !== "" && fieldValue["AgeFrom"] !== undefined) {
        if (Data[i].Age >= parseInt(fieldValue["AgeFrom"])) {
          matchAgeFrom = true
        } else { matchAgeFrom = false }
      } else { matchAgeFrom = true }

      if (fieldValue["AgeTo"] !== "" && fieldValue["AgeTo"] !== undefined) {
        if (Data[i].Age <= parseInt(fieldValue["AgeTo"])) {
          matchAgeTo = true
        } else { matchAgeTo = false }
      } else { matchAgeTo = true }

      if (matchOrgId === true && matchNationality === true && matcGender === true && matchAgeFrom === true && matchAgeTo === true) {
        newDocList.push(Data[i])
      }
    }
    // setDocList(newDocList)
    getPageAmount(newDocList)
    fetchBySize(fetchFrom, fetchTo, newDocList)
  }
  function fetchBySize(fetchFrom, fetchTo, Data) {
    console.log("fetchFrom", fetchFrom, "fetchTo", fetchTo)
    let newDocList = []
    for (let i = fetchFrom; i < fetchTo; i++) {
      if (Data[i] !== undefined) {
        newDocList.push(Data[i])
      }
    }
    setDocList(newDocList)
  }
  function KeyboardArrowFirstClick() {
    setPage(1)
    filterDocList(0, size - 1, gridFormData)
  }
  function KeyboardArrowLeftClick(page) {
    if (page !== 1) {
      var prevPage = page - 1
      setPage(prevPage)
      let fetchFrom = ((prevPage - 1) * size)
      let fetchTo = (size * prevPage) - 1
      filterDocList(fetchFrom, fetchTo, gridFormData)
    }
  }
  function KeyboardArrowRightClick(page) {
    if (docList.length < size - 1) {
      console.log("NO DATA")
    }
    else {
      setPage(page + 1)
      let fetchFrom = (size * page) - 1
      let fetchTo = ((page + 1) * size) - 1
      filterDocList(fetchFrom, fetchTo, gridFormData)
    }

  }
  function handleChangeRowsPerPage(event) {
    setSize(event.target.value)
    filterDocList(0, event.target.value, gridFormData)
    console.log("Rows amount changed: ", event.target.value)
  }
  function getUUID() {
    const uuidv1 = require("uuid/v1")
    return uuidv1()
  }
  function handleChange(event) {
    console.log("EVENT", event)
    fieldValue[event.target.name] = event.target.value
    setFieldValue(fieldValue)
    console.log("FIELDVALUE", fieldValue)
  }
  function handleSelectChange(event) {
    fieldValue[event.name] = event.value
    setFieldValue(fieldValue)
    let updateSelectedOptions = selectedOptions.slice()
    let noSuchOption = true
    for (let i = 0; i < updateSelectedOptions.length; i++) {
      if (event.name === updateSelectedOptions[i].name) {
        updateSelectedOptions[i] = event
        setSelectedOptions(updateSelectedOptions)
        // console.log("SO", selectedOptions)
        noSuchOption = false
        break
      }
      else {
        noSuchOption = true
      }
    }
    if (noSuchOption === true) {
      updateSelectedOptions.push(event)
      setSelectedOptions(updateSelectedOptions)
      console.log("SO", selectedOptions)
    }
  }
  const handleSwitchChange = (event) => {
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked });
  }
  async function buttonClick(name, childData) {
    console.log("childData", childData)
    if (name === "createCandidateMeetUpRequest") {
      swal({
        text: "ВНИМАНИЕ после выбора этого ребенка вы больше не сможете просматривать и выбирать других детей!",
        icon: "warning",
        buttons: { yes: "Да", cancel: "Отмена" }
      })
        .then((click) => {
          if (click === "yes") {
            request(
              {
                "headers": { "content-type": "application/json" },
                "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetActiveRequestId?childId=" + childId,
                "method": "GET"
              }
            )
              .then(function (response) {
                let resp = JSON.parse(response)
                // console.log("RESPONSE", resp)
                if (resp === 0) {
                  request(
                    {
                      "headers": { "content-type": "application/json" },
                      "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Create?childId=" + childId + "&userId=" + userId,
                      "method": "GET"
                    }
                  )
                    .then(function (response) {
                      // console.log("RESPONSE", JSON.parse(response))
                      getUserStatus()
                      setDocList([])
                      setGridFormData([])
                      setTask("getActiveList")
                      swal({
                        text: "Запрос на встречу успешно создан!",
                        icon: "success"
                      })
                    })
                    .catch(function (error) {
                      return console.log("Request error: ", error)
                    })
                }
                else {
                  request(
                    {
                      "headers": { "content-type": "application/json" },
                      "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Join?meetupRequestId=" + resp + "&userId=" + userId,
                      "method": "GET"
                    }
                  )
                    .then(function (response) {
                      // console.log("RESPONSE", JSON.parse(response))
                      getUserStatus()
                      setDocList([])
                      setGridFormData([])
                      setTask("getActiveList")
                      swal({
                        text: "Запрос на встречу успешно создан!",
                        icon: "success"
                      })
                    })
                    .catch(function (error) {
                      return console.log("Request error: ", error)
                    })

                }
              })
              .catch(function (error) {
                return console.log("Request error: ", error)
              })

          } else {
            console.log("CLICK", click)
          }
        })
      console.log("Button", name)
    }
    else if (name === "findChild") {
      // console.log("GRIDDATA", gridFormData)
      filterDocList(0, size, gridFormData)
      console.log("Button", name, size)
    }
    else if (name === "viewChildData") {
      setChildId(childData.DocumentId)
      getCountMeetUpRequestByChildId(childData.DocumentId)
      setButtons(Buttons[userRole].ViewChildDataButtons)
      setTask("viewChildData")
      console.log("Button", name, childData)
    }
    else if (name === "viewChildDataBack") {
      // fetchChildren()
      setForm(SearchChildrenForm)
      setButtons(Buttons[userRole].SearchChildrenButtons)
      setGridFormButtons(Buttons[userRole].ChildrenGridFormButtons)
      setEnumData(EnumData)
      setTask("getActiveList")
      console.log("Button", name, childData)
    }
    else {
      console.log("Button ", name)
    }
  }
  function getDistricts(region) {
    var indents = []
    indents.push(<ListSubheader>{region.regionLabel}</ListSubheader>)
    for (var i = 0; i < region.districts.length; i++) {
      indents.push(<MenuItem value={region.districts[i].Id}>{region.districts[i].Text}</MenuItem>)
    }
    return indents
  }
  function sectionBuilder(section, index) {
    // creating table and label of section and components
    return (
      <MTable size="small" key={index}>
        <TableHead>
          <TableRow style={{ height: 30 }}>
            <TableCell key={index} style={{
              color: "black",
              fontSize: 14,
              backgroundColor: "#D3D3FE"
            }}>{section.label}</TableCell>
            <TableCell style={{ backgroundColor: "#D3D3FE" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {section.contents.map((contentItem, index) => (
            <TableRow key={index} style={{ height: 30, maxWidth: 200 }}>
              {contentItem.label !== "" &&
                <TableCell
                  key={getUUID()}
                  align="left"
                  style={{ maxWidth: 200 }}>
                  {contentItem.label}
                </TableCell>
              }
              <TableCell
                key={getUUID()}
                align="left"
                style={{ maxWidth: 200, height: 30 }}
              >{contentBuilder(contentItem)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MTable>
    )
  }
  function contentBuilder(contentItem) {
    // Creating components by it's type
    if (contentItem.type === "Text") {
      return (
        <TextField
          multiline
          // onChange = {componentChange}
          onBlur={handleChange}
          name={contentItem.name}
          style={{ minWidth: 250 }}
          disabled={(contentItem.edit === false) ? true : false}
          defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          type="date"
          name={contentItem.name}
          onBlur={handleChange}
          style={{ minWidth: 250 }}
          // defaultValue = {fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : "NaN.NaN.NaN" }
          defaultValue={fieldValue[contentItem.name]}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
    else if (contentItem.type === "Switch") {
      return (
        <Switch
          // defaultChecked
          name={contentItem.name}
          onChange={handleSwitchChange}
          color="primary"
          // disabled={(contentItem.edit === false) ? true : false}
          checked={fieldValue[contentItem.name]}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )
    }
    else if (contentItem.type === "Enum") {
      let options = []
      options.push({
        "value": "",
        "label": "",
        "name": contentItem.name
      })
      for (let i = 0; i < enumData.length; i++) {
        if (contentItem.enumDef === enumData[i].enumDef) {
          for (let l = 0; l < enumData[i].data.length; l++) {
            options.push({
              "value": enumData[i].data[l].Id,
              "label": enumData[i].data[l].Text,
              "name": contentItem.name
            })
          }
        }
      }
      let selectedOption = {
        "value": "",
        "label": "",
        "name": contentItem.name
      }
      // console.log("ORG", contentItem.name, fieldValue[contentItem.name], organizationId)
      if (fieldValue[contentItem.name] !== undefined) {
        for (let i = 0; i < options.length; i++) {
          if (fieldValue[contentItem.name] === options[i].value) {
            selectedOption = options[i]
            // console.log("OPT", options[i])
            break
          }
        }
      }
      if (selectedOptions.length !== 0) {
        for (let i = 0; i < selectedOptions.length; i++) {
          if (contentItem.name === selectedOptions[i].name) {
            selectedOption = selectedOptions[i]
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          className={classesnav.select}
          value={selectedOption}
          onChange={handleSelectChange}
          options={options}
          isDisabled={(contentItem.edit === false) ? true : false}
        />
      )
    }
    else if (contentItem.type === "DistrictEnum") {
      return (
        <FormControl className={classes.formControl}>
          <MaterialSelect
            name={contentItem.name}
            style={{ height: 40 }}
            variant="outlined"
            value={fieldValue[contentItem.name]}
            onChange={handleChange}
          >
            {Districts.map((region, index) => {
              return (
                getDistricts(region))
            })}
          </MaterialSelect>
        </FormControl>
      )
    }
    else if (contentItem.type === "Int") {
      return (
        <TextField
          style={{ minWidth: 250 }}
          name={contentItem.name}
          defaultValue={(fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : ""}
          onBlur={handleChange}
          InputProps={{ inputComponent: NumberFormatCustom }}
        />
      )
    }
  }
  function getGridFormItems(value, type) {
    // console.log("GRIDITEM", value, type)
    if (type === "Enum" || type === "DistrictEnum") {
      for (let i = 0; i < enumData.length; i++) {
        for (let l = 0; l < enumData[i].data.length; l++) {
          if (enumData[i].data[l].Id === value) {
            // console.log("GRIDITEM", enumData[i].data[l].Text)
            return enumData[i].data[l].Text
          }
        }
      }
    }
    else if (type === "DateTime") {
      return parseDateComponent(value)
    }
    else {
      return value
    }
  }
  function getPageAmount(data) {
    let pagesFloat = (data.length + 1) / size
    let mathRoundOfPages = Math.round(pagesFloat)
    if (pagesFloat > mathRoundOfPages) {
      setPageAmount(mathRoundOfPages + 1)
    }
    else {
      setPageAmount(mathRoundOfPages)
    }
  }
  // console.log("Child PROPS", props)
  // console.log("DOCS", docList)
  // console.log("ENUM", enumData)
  console.log("FIELDS", fieldValue)
  // console.log("SOP", selectedOptions)
  if (Form !== null && docList !== null && task === "getActiveList") {
    // console.log("FORM", Form)
    return (
      <Grid>
        <Grid container direction="row" justify="flex-start" spacing={0}>
          <Grid item xs={7}>
            <Paper>
              <MTable size="small">
                <Grid container direction="row" justify="center" alignItems="center">
                  <TableHead>
                    <TableRow style={{ maxHeight: 25 }}>
                      <TableCell style={{ fontSize: 16, color: "black" }}>{Form.label}</TableCell>
                    </TableRow>
                  </TableHead>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                  {Form.sections.map((section, index) => {
                    return sectionBuilder(section, index)
                  })}
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  {buttons.map((button, index) =>
                    <Button
                      key={getUUID()}
                      name={button.name}
                      variant="outlined"
                      value={button.name}
                      onClick={() => buttonClick(button.name, null)}
                      style={{
                        margin: 3,
                        backgroundColor: button.backgroundColor,
                        height: 32,
                        fontSize: 12
                      }}
                    >{button.label}
                    </Button>
                  )}
                </Grid>
              </MTable>
            </Paper>
          </Grid>
        </Grid>
        <br></br>
        {Object.keys(docList).length !== 0 &&
          <Grid container direction="row" justify="flex-start" spacing={0}>
            <Grid item sm={"auto"}>
              <Paper>
                <Table size="small">
                  <TableHead style={{ backgroundColor: "#D3D3FE" }}>
                    <tr>
                      <th colSpan="1"></th>
                      {gridForm.sections.map((section, index) => {
                        return (
                          <th colSpan={section.contents.length} key={index} style={{ color: "black", fontSize: 15 }}>{section.label}</th>
                        )
                      })}
                    </tr>
                    <tr>
                      <th rowSpan="2" key="action" style={{ color: "black", fontSize: 14, minWidth: 50 }}>Действие</th>
                    </tr>
                    <tr>
                      {gridForm.sections.map(section =>
                        section.contents.map((contentItem, index) => {
                          return (
                            <th rowSpan="2" key={index} style={{ color: "black", fontSize: 14 }}>{contentItem.label}</th>
                          )
                        })
                      )}
                    </tr>
                  </TableHead>
                  <TableBody>
                    {docList.map((dataItem, index) => (
                      <tr key={index} style={{ height: 30 }}>
                        <td key={index + "td"} style={{ maxWidth: 50 }}>
                          {gridFormButtons !== null &&
                            gridFormButtons.map((button, index) =>
                              <Button
                                key={index}
                                name={button.name}
                                variant="outlined"
                                // id={dataItem.id}
                                value={button.name}
                                onClick={() => buttonClick(button.name, dataItem)}
                                style={{
                                  margin: 1,
                                  height: 24,
                                  fontSize: 9,
                                  maxWidth: 32,
                                  backgroundColor: button.backgroundColor
                                }}
                              >{button.label}
                              </Button>
                            )}
                        </td>
                        {gridForm.sections.map(section => {
                          return (
                            section.contents.map((contentItem, index) => {
                              for (let key in dataItem) {
                                if (key === contentItem.name) {
                                  if (dataItem[key] === null || dataItem[key] === "") {
                                    return (
                                      <td key={index} align="center" style={{ color: "black", fontSize: 12 }}>-</td>
                                    )
                                  }
                                  else {
                                    return (
                                      <td key={index} align="center" style={{ color: "black", fontSize: 12 }}>
                                        {getGridFormItems(dataItem[key], contentItem.type)}
                                      </td>
                                    )
                                  }
                                }
                              }
                            })
                          )
                        })}
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        }
        <Grid container direction="row" justify="flex-start" spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell style={{ minWidth: 90, color: "black" }}>
                      Кол-во записей
                    </TableCell>
                    <TableCell>
                      <FormControl
                        variant="outlined"
                        style={{ minWidth: 30 }}
                      >
                        <GridSelect
                          onChange={handleChangeRowsPerPage}
                          style={{ height: 25, color: "black" }}
                          value={size}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={15}>15</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                        </GridSelect>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => KeyboardArrowFirstClick()}>
                        <FirstPageIcon style={{ fontSize: "large", color: "primary" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => KeyboardArrowLeftClick(page)}>
                        <ArrowBackIosIcon style={{ fontSize: "medium" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ color: "black", fontSize: 16 }}>Стр: {page} из {pageAmount}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => KeyboardArrowRightClick(page)}>
                        <ArrowForwardIosIcon style={{ fontSize: "medium" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  else if (childId !== null && task === "viewChildData") {
    return (
      <Grid>
        <Paper>
          <ViewChildData
            portalAPI={portalAPI}
            keycloakAPI={keycloakAPI}
            viewAddress={userRole === "candidate" ? false : true}
            childId={childId}
          />
          <MTable size="small">
            <TableHead style={{ backgroundColor: "#D7E3DD" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Количество заявок</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Срок окончания заявок на встречу</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{countMeetUpRequests.Count === "" ? 0 : countMeetUpRequests.Count}</TableCell>
                <TableCell>{countMeetUpRequests.Deadline === "" ? "-" : parseDate(countMeetUpRequests.Deadline)}</TableCell>
              </TableRow>
            </TableBody>
          </MTable>
          {buttons.map((button, index) =>
            <Button
              key={index}
              name={button.name}
              variant="outlined"
              value={button.name}
              onClick={() => buttonClick(button.name, null)}
              style={{
                margin: 1,
                fontSize: 11,
                backgroundColor: button.backgroundColor
              }}
            >{button.label}
            </Button>
          )}
        </Paper>
      </Grid>
    )
  }
  else {
    return (
      <div align="center" style={{ paddingTop: 250 }}>
        <CircularProgress />
      </div>
    )
  }
};
